<template>
    <div v-bind="$attrs" v-on="$listeners">
        <!--显示登录人员管理的机构组与机构组组内绑定的门店,权限相关-->
        <el-select
            filterable
            :loading="!meta.groups.length"
            style="width: 180px"
            @change="changeGroup"
            placeholder="请选择机构组"
            :clearable="selAllGroup"
            ref="efnSel1"
            v-model="form.groupCode"
        >
            <template v-if="selAllGroup">
                <el-option value="" key="" label="全部"></el-option>
            </template>

            <el-option v-for="group in meta.groups" :value="group.code" :key="group.code" :label="group.name" />
        </el-select>

        <el-select
            placeholder="请选择店面"
            filterable
            :clearable="selAllDept"
            @change="changeDept"
            ref="efnSel2"
            v-model="form.deptCode"
        >
            <template v-if="selAllDept">
                <el-option value="" key="" label="全部"></el-option>
            </template>
            <el-option v-for="dept in meta.depts" :label="dept.name" :value="dept.code" :key="dept.code" />
        </el-select>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    //todo 按级联组件调整初始化逻辑
    name: 'EfnGroupDept',
    props: {
        selAllGroup: {
            type: Boolean,
            default: true,
        },
        selAllDept: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Array,
            default: () => {
                return ['', ''];
            },
        },
    },
    data() {
        return {
            loadingDeptGroupFlag: true,
            form: {
                deptCode: '',
                groupCode: '',
            },
            queryDeptByGroup: '/system/dept/deptTree',
            meta: {
                depts: [],
                groups: [],
            },
        };
    },
    watch: {
        value: {
            handler: async function (val, oldVal) {
                if (val && val[0] && oldVal && !oldVal[0]) {
                    const _this = this;
                    this.form.groupCode = val[0] ? val[0] : '';
                    const _depts = await _this.depts(_this.form.groupCode);
                    _this.meta.depts = _depts;
                    const _deptCode = val[1] ? val[1] : '';
                    _this.form.deptCode = _deptCode;
                    this.changeDept();
                }
            },
            deep: true,
        },
    },
    async mounted() {
        const _this = this;
        const _groups = (await this.groups()) || [];
        const _groupCode = this.value[0] ? this.value[0] : _groups.length ? _groups[0].code : '';
        if (!_groupCode) {
            return;
        }
        const _depts = await this.depts(_groupCode);
        const _deptCode = this.value[1] ? this.value[1] : _depts.length ? _depts[0].code : '';
        _this.$set(_this.meta, 'groups', _groups);
        (() => {
            if (!this.value[1]) {
                _this.meta.depts = _depts;
                _this.form.groupCode = _groupCode;
                _this.form.deptCode = _deptCode;
                this.changeDept();
            }
        })();
    },
    methods: {
        async groups() {
            return new Promise((resolve) => {
                UrlUtils.DeptGroup(this, (rst) => {
                    resolve(rst);
                    return;
                });
            });
        },
        async depts(groupCode) {
            this.loadingStart();
            const _this = this;
            const rst = await this.$http.get(_this.queryDeptByGroup + '?deptGroupCode=' + groupCode);
            this.loadingStop();
            return rst.data.data;
        },

        async changeGroup(groupCode) {
            const _this = this;
            const rst = await this.depts(groupCode);
            this.form.groupCode = groupCode;
            this.meta.depts = rst;
            if (_this.meta.depts.length >= 1) {
                _this.form.deptCode = _this.meta.depts[0].code;
            } else {
                _this.form.deptCode = '';
            }

            _this.loadingDeptGroupFlag = false;
            this.$emit('change', {
                groupCode: _this.form.groupCode,
                groupName: _this.groupName(),
                deptCode: _this.form.deptCode,
                deptName: _this.deptName(),
            });
        },
        async changeDept() {
            const _this = this;
            this.$emit('change', {
                groupCode: _this.form.groupCode,
                groupName: _this.groupName(),
                deptCode: _this.form.deptCode,
                deptName: _this.deptName(),
            });
        },
        groupName() {
            if (!this.form.groupCode) {
                return '';
            }
            const _g = this.meta.groups.find((a) => a.code == this.form.groupCode);
            return _g ? _g['name'] : '';
        },
        deptName() {
            if (!this.form.deptCode) {
                return '';
            }
            const _d = this.meta.depts.find((a) => a.code == this.form.deptCode);
            return _d ? _d['name'] : '';
        },
    },
};
</script>
